import Axios from 'axios';
import { Message } from 'element-ui';
import Store from '@/store';
import CryptoJS from 'crypto-js';
import Toast from 'vant/lib/toast';

const secret = '21ca4ac31709a30978845b7a4e700b19';


// Axios.defaults.baseURL = 'https://heya-assistant.genshuixue.com';
// Axios.defaults.baseURL = 'https://api.kwydzs.com';

// if (process.env.NODE_ENV === 'development') {
//     Axios.defaults.baseURL = 'https://api-beta.kwydzs.com';
// }

// if (process.env.NODE_ENV == 'beta') {    
//     Axios.defaults.baseURL = 'https://api-beta.kwydzs.com';
// }

function generateRandomString(length) {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
}

function sortObjectByKey(obj) {
    var sortedObj = {};
    var keys = Object.keys(obj).sort();
    for (var i = 0; i < keys.length; i++) {
        sortedObj[keys[i]] = obj[keys[i]];
    }
    return sortedObj;
}

function objectToQueryString(obj) {
    var sortedObj = sortObjectByKey(obj);
    var queryString = '';
    for (var key in sortedObj) {
        if (sortedObj.hasOwnProperty(key) && sortedObj[key] !== '' && sortedObj[key] !== undefined) {
            queryString += key + '=' + encodeURIComponent(sortedObj[key]) + '&';
        }
    }
    return queryString.slice(0, -1);
}

function createObj() {
    return {
        timestamp: parseInt(new Date().getTime() / 1000),
        nonce: generateRandomString(6)
    };
}

Axios.interceptors.request.use(
    (config) => {
        config.data = Object.assign(config.data || {}, createObj());

        const hash = CryptoJS.HmacSHA1(objectToQueryString(config.data), secret);
        const base64 = hash.toString(CryptoJS.enc.Base64);

        config.data.sign = base64
        // config.headers.token = Store.state.token;
        config.headers.Authorization = `Bearer ${Store.state.token}`
        localStorage.setItem('token', config.headers.Authorization)
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

Axios.interceptors.response.use(
    (response) => {
        if (response.data.code === 2030000 || response.data.code === 4001) {
            Message.warning('请重新登录');
           
            Store.commit('updateToken', null);
            // window.location.href = '/login';
            return;
        }
        if (response.data.code === 200 ||response.data.ret_code === 0 ) {
            return response.data;
        } else {
            if (response.data.msg !== 'token无效，请重新登录') {
                Message.warning(response.data.msg || response.data.message);
            }
            
            
            return Promise.reject(response.data);
        }
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default Axios;

import Axios from '../common/http.js';
import store from '../store'; 

/**
 * 验证请求成功
 */
export const getLoginStatus = (data) => {
	return Axios.post('/api/h5/essay/login', data);
};

/**
 * 登录二维码请求
 */
export const getLoginCode = (params) => {
	return Axios.get('/api/h5/essay/qrcode', {params});
};

/**
 * 文体列表
 */
export const getEssayList = (params) => {
	return Axios.get('/api/teacher/tools/essay/get', {params});
};

/**
 * ocr识别
 */
export const ocrApi = (data) => {
	return Axios.post('/api/teacher/tools/essay/ocr', data);
};

/**
 * 提交批改
 */
export const submitCorrect = (data) => {
	return Axios.post('/api/teacher/tools/essay/add', data);
};

/**
 * 批改详情
 */
export const getCorrectDetail = (params) => {
	return Axios.get('/api/teacher/tools/essay/detail', {params});
};
/**
 * 上传文件
 */
export const uploadFile = (data) => {
	return Axios.post('/api/teacher/upload/upload_file', data);
};
/**
 * 问题反馈
 */
export const feedbackApi = (data) => {
	return Axios.post('/api/teacher/homework/add_error', data);
};

/**
 * 批改记录
 */
export const correctList = (params) => {
	return Axios.get('/api/teacher/tools/essay/list', {params});
};


/**
 * 批改单个
 */
export const correctSingle = (data) => {
	return Axios.post('/api/teacher/tools/essay/addsingle', data);
};


/**
 * 获取风格
 */
 export const getStyle = (data) => {
	return Axios.get('/api/teacher/tools/essay/style', data);
};

export const addstyle = (data)=>{
	return Axios.post("/api/teacher/tools/essay/addstyle", data)
}

export const getActivestate = (data) => {
	return Axios.get('/api/teacher/tools/essay/activestate', data);
};

// 删除作文
export const essayRemoveApi = (data) => {
	return Axios.post('/api/teacher/tools/essay/remove', data);
};


export const getModel = function(params) {
    return Axios.get('/api/teacher/tools/essay/getmodel', {params});
}
//请求范文结果
export const addModel = function(data) {
    return Axios.post('/api/teacher/tools/essay/addmodel', data);
}

// 工具集全部列表
export const aiToolAllList = function(data) {
	const app = store.state;
	const { subject_id } = app.userInfo;
    return Axios.get('/go/api/teacher/ai/tool/all', data).then(res => {
    	return res.data.filter(r => r.subjectIds.indexOf(subject_id) !== -1)
    });
}

// 工具集详情
export const aiToolDetail = function(data) {
    return Axios.get('/go/api/teacher/ai/tool/detail', {params: data});
}

// AI工具用户详情
export const aiToolMemberDetail = function(params) {
    return Axios.get('/go/api/teacher/ai/tool/member/detail', {params});
}

// 敏感词过滤
export const sensitiveCheck = function(data) {
    return Axios.post('/go/api/teacher/sensitive/check', data);
}




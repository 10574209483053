import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '../views/layout/layout.vue';
import Operate from '../views/layout/operate.vue';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [
    {
        path: '/',
        component: Layout,
        redirect: '/operate',
        children: [
            {
                path: '/operate',
                component: Operate,
                redirect: '/correct',
                children: [
                    {
                        path: '/correct',
                        name: 'correct',
                        component: () =>
                            import(
                                /* webpackChunkName: "correct" */ '../views/correct/correct.vue'
                            )
                    },
                    {
                        path: '/correct/record',
                        name: 'record',
                        component: () =>
                            import(
                                /* webpackChunkName: "correct" */ '../views/correct/record.vue'
                            )
                    },
                    {
                        path: '/feedback',
                        name: 'feedback',
                        component: () =>
                            import(
                                /* webpackChunkName: "feedback" */ '../views/feedback/feedback.vue'
                            )
                    },
                    {
                        path: '/official',
                        name: 'official',
                        component: () =>
                            import(
                                /* webpackChunkName: "official" */ '../views/official/official.vue'
                            )
                    },
                    {
                        path: '/mine',
                        name: 'mine',
                        component: () =>
                            import(
                                /* webpackChunkName: "mine" */ '../views/mine/mine.vue'
                            )
                    },
                    {
                        path: '/aiToolsList',
                        name: 'aiToolsList',
                        component: () =>
                            import(
                                /* webpackChunkName: "aiToolsList" */ '../views/aiToolsList/aiToolsList.vue'
                            )
                    },
                    {
                        path: '/templateAi',
                        name: 'templateAi',
                        component: () =>
                            import(
                                /* webpackChunkName: "templateAi" */ '../views/aiToolsList/templateAi.vue'
                            )
                    },
                    {
                        path: '/chat',
                        name: 'chat',
                        component: () =>
                            import(
                                /* webpackChunkName: "chat" */ '../views/aiToolsList/chat.vue'
                            )
                    }
                ]
            },
            {
                path: '/preview',
                name: 'preview',
                component: () =>
                    import(
                        /* webpackChunkName: "preview" */ '../views/previewFile/previewFile.vue'
                    )
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "login" */ '../views/login/login.vue')
    },
    {
        path: '/disk/share',
        name: 'diskShare',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/poster/diskShare.vue')
    },
    {
        path: '/invite/notice',
        name: 'inviteNotice',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/invite/notice.vue')
    },
    {
        path: '/invite/qrcode',
        name: 'inviteQrcode',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/invite/qrcode.vue')
    },
    {
        path: '/certificate',
        name: 'certificate',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/certificate/certificate.vue')
    },
    {
        path: '/compositionTask',
        name: 'compositionTask',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/compositionTask/compositionTask.vue')
    },
    {
        path: '/poster/resourceShare',
        name: 'resourceShare',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/poster/resourceShare.vue')
    },
    {
        path: '/poster/essayShare',
        name: 'essayShare',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/poster/essayShare.vue')
    },
    {
        path: '/poster/taskShare',
        name: 'taskShare',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/poster/taskShare.vue')
    },
    {
        path: '/english',
        name: 'english',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../views/correct/english.vue')
    },
    {
        path: '/voice',
        name: 'voice',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/voice/voice.vue')
    },
    {
        path: '/composition/rank',
        name: 'compositionRank',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/poster/compositionRank.vue')
    },
    {
        path: '/moments',
        name: 'moments',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/poster/moments.vue')
    },
    {
        path: '/download',
        name: 'download',
        component: () =>
            import(/* webpackChunkName: "mobile" */ '../mobile/download/download.vue')
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router;
